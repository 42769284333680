var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "side" },
    [
      _c(
        "router-link",
        {
          staticClass: "side-content button",
          class: { active: _vm.isActivePath("/agent") },
          attrs: { to: { name: "agent" } },
        },
        [_vm._v(" エージェント提出作品 ")]
      ),
      _c(
        "router-link",
        {
          staticClass: "side-content button",
          class: { active: _vm.isActivePath("/agent/description") },
          attrs: { to: { name: "agentDescription" } },
        },
        [_vm._v(" 提出にあたって ")]
      ),
      _c(
        "router-link",
        {
          staticClass: "side-content button",
          class: { active: _vm.isActivePath("/agent/notifications") },
          attrs: { to: { name: "agentNotifications" } },
        },
        [
          _vm._v(" 受信箱 "),
          _vm.unreadItemCount > 0
            ? _c("div", { staticClass: "badge" }, [
                _vm._v(_vm._s(_vm.unreadItemCount)),
              ])
            : _vm._e(),
        ]
      ),
      _c("CarouselBanner", { staticClass: "carousel-banner" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }